.card {
    width: 100%;
    background-color: var(--white);
    display: flex;
    border-radius: 12px;
}
 .card .card__img, main .card .card__content {
    width: 100%;
}
 .card .card__img picture {
    width: 100%;
    height: 100%;
}
 .card .card__img picture img {
    width: 100%;
    height: 100%;
    border-radius: 12px 0px 0px 12px;
}

 .card .card__content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}
 .card .card__content .card__tag {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 14px;
    color: var(--dark-grayish-blue);
}
 .card .card__content .card__title {
    font-family: 'Fraunces', serif;
    text-transform: capitalize;
}
 .card .card__content .card__desc {
    font-size: 14px;
    color: var(--dark-grayish-blue);
    line-height: 24px;
}
 .card .card__content .card__price {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
 .card .card__content .card__price h1 {
    color: var(--dark-cyan);
    font-family: 'Fraunces', serif;
}
 .card .card__content .card__price s {
    font-size: 14px;
    color: var(--dark-grayish-blue);
}
 .card .card__content .card__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: var(--dark-cyan);
    padding: 14px;
    outline: none;
    border: 0;
    border-radius: 10px;
    color: var(--white);
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    transition: background 200ms linear;
}
 .card .card__content .card__btn:hover {
    background-color: var(--very-dark-blue);
}

@media (max-width: 600px) {
     .card {
        flex-direction: column;
        margin-top : 20px
    }
     .card .card__img, main .card .card__content {
        width: 100%;
    }
     .card .card__img picture img {
        border-radius: 12px 12px 0px 0px;
    }
     .card .card__content {
        padding: 20px;
        row-gap: 16px;
    }
}