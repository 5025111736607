$white: hsl(0, 0%, 100%);
$light-gray: hsl(212, 45%, 89%);
$grayish-blue: hsl(220, 15%, 55%);
$dark-blue: hsl(218, 44%, 22%);




    .card {
        background: $white;
        box-shadow: 2px 2px 25px 0px rgba(128, 128, 128, 0.247);
        border-radius: 25px;
        padding: 15px;
        height: 500px;
        width: 340px;

        .card-img {
            width: 100%;
            img {
                width: 100%;
                border-radius: 12px;
            }
        }
        .card-content {
            width: 100%;
            margin-top: 20px;
            padding: 0px 10px;

            h1 {
                color: $dark-blue;
                font-size: 24px;
                text-align: center;
                font-weight: 700;
            }
            p {
                margin-top: 15px;
                color: $grayish-blue;
                text-align: center;
                font-weight: 400;
            }
        }
    }
