.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
  p {
    margin-top: 10px;
  }
  button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
}
.stepwizard-row {
  display: table-row;
  &:before {
    top: 34px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: transparent;
    z-order: 0;
  }
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
.btn-circle {
  width: 10px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.form-step {
  width: 100%;
}
