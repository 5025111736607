.btn-inquiry-now{
    background-color: #01419A;
    border:  1px solid transparent;
    color: white;
    font-size: 18px;
    text-transform: none;
    height: 70px;
    border-radius: 5px;
}

.btn-inquiry-now:hover{
    background-color: #005BC6;
    color: white;
    border:1px solid #01419A;
    font-size: 18px;
    text-transform: none;
    transition: 1.2s;
    height: 70px;
    border-radius: 5px;

}

.btn-inquiry-now::after{
    background-color: #fff;
    color: #01419A;
    border:1px solid #01419A;
    font-size: 18px;
    text-transform: none;
    transition: 1.2s;
    height: 70px;
    border-radius: 5px;

}


.service-link-css{
    color:#01419A;
    text-decoration: underline;
}

.service-link-css:hover{
    color:#fff;
    text-decoration: underline;
}
