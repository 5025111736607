.btn-inquiry{
    font-size: 20px;
    height : 70px;
    color: white;
    font-weight: "normal";
    background-color: transparent;
    border: 2px solid white;
    border-radius: 5px;
    transition: 2s;
    width: 210px;

}

.btn-inquiry:hover{
    font-size: 20px;
    height : 70px;
    color: #fff;
    font-weight: "normal";
    background-color: transparent;
    border: 2px solid #01419A;
    background-color: #01419A;
    border-radius: 5px;
    transition: 2s;
    width: 210px;

}