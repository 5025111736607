.container__ {
    display: flex;
    width: 100vw;
  }
  
  .panel {
    display: flex;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    margin-left: 100px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 700ms ease-in;
  }
  
  .panel h3 {
    font-size: 24px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    opacity: 0;
  }
  
  .panel.active {
    flex: 5;
  }
  
  .panel.active h3 {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.4s;
  }
  
  @media (max-width: 480px) {
    .container {
      width: 100vw;
    }
  
    .panel:nth-of-type(4),
    .panel:nth-of-type(5) {
      display: none;
    }
  }