$strokeWidth: 4px;
$strokeColor: black;

$timing: 2.6s;
// $easing: ease-in-out;
$easing: cubic-bezier(0.77, 0, 0.175, 1);

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.scroll-down-dude:before,
.scroll-down-dude:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border-bottom: $strokeWidth solid $strokeColor;
  border-right: $strokeWidth solid $strokeColor;
}

.scroll-down-dude:before {
  animation: down-arrow-before $timing $easing infinite;
}

.scroll-down-dude:after {
  animation: down-arrow-after $timing $easing infinite;
}

@keyframes down-arrow-before {
  50% {
    transform: rotate(45deg) translate(70%, 70%);
  }
  100% {
    transform: rotate(45deg) translate(70%, 70%);
  }
}

@keyframes down-arrow-after {
  50% {
    transform: rotate(45deg) translate(110%, 110%);
    opacity: 0;
  }
  51% {
    transform: rotate(45deg) translate(-130%, -130%);
  }
  100% {
    transform: rotate(45deg) translate(-70%, -70%);
    opacity: 1;
  }
}
